import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from "firebase/messaging";
var firebaseConfig = {
    apiKey: "AIzaSyBbBOOUlj0YoxjWPbm8GYI3G3Q3mmTosj4",
    authDomain: "ayo-healthcare.firebaseapp.com",
    projectId: "ayo-healthcare",
    storageBucket: "ayo-healthcare.appspot.com",
    messagingSenderId: "476504350503",
    appId: "1:476504350503:web:c4bc7336d029fee7c8d302"
};

//initializeApp(firebaseConfig);
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const fetchToken = (setTokenFound) => {
  return getToken(messaging, {vapidKey: 'BNLFeDt0Is9WzcnXMdYkwr-Cc51VeT0hoMgzL3A5r0MCjC53_n3EsKxj11K0D0OlUbof2TGuNY0mhCQ3O22CBYw'}).then((currentToken) => {
    if (currentToken) {
      console.log('current token for client: ', currentToken);
      setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});